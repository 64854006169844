<template>
  <FormModal
    :title="$t('field.estimated-annual-electricity-consumption')"
    show-back-btn
  >
    <template #body>
      <ElectricityEstimationFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import ElectricityEstimationFormStep from '~/components/form/steps/electricityEstimation/ElectricityEstimationFormStep'

export default {
  components: {
    ElectricityEstimationFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Schatting jaarlijks elektriciteitsverbruik',
      headDescription: 'Geef wat voor type batterij je op zoek bent.',
      path: '/offertes-aanvragen/schatting-jaarlijks-elektriciteitsverbruik',
      progressValue: 50,
      checkoutStep: 2,
    }
  },
}
</script>
